import {
    medisage_blog
  } from "../../js/path";   
  import _ from 'lodash';
  
  export default {
    data() {
      return {
        pageNo: null,
        status: false,
        oldForm: {},
        categories_id: null,
        table_header: [],
        fields: [{
            key: "id",
            sortable: true,
          },
          {
            key: "name",
            label: 'Category Name'
          },
          {
            key: "is_active",
            label: "Status",
          },
          {
            key: "edit",
          },
          {
            key: "delete",
          },
        ],
        params: "",
        tableData: [],
        activeTab: "all",
        key: 0,
        currentPage: 1,
        filter: null,
        filterOn: [],
        sortBy: "id",
        sortDesc: true,
        forum: [],
        partner: [],
        community: [],
        expert: [],
        knowledgeCategory: [],
        videos: [],
        form: {
          name: "",
          slug: ""
        },
        image_url: "",
        image: "",
        edit: {
          image_url: null,
        },
      };
    },
    methods: {
      slugify() {
        this.category_name = this.form.name;
        if (this.category_name) {
          const slug_generate = this.category_name
            .toString()
            .trim()
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/[^\w-]+/g, "")
            .replace(/--+/g, "-")
            .replace(/^-+/, "")
            .replace(/-+$/, "");
          this.form.slug = slug_generate;
        } else {
          this.form.slug = null;
        }
      },
      readFile(e, txt) {
        if (txt == "image") {
          this.image = e.target.files[0];
          if (this.image.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded File is More than 2MB"
            });
            this.$refs.image.reset();
            this.image_url = '';
            this.edit.image_url = '';
            this.image = '';
            return false;
          } else {
            this.image_url = URL.createObjectURL(this.image);
            this.edit.image_url = '';
          }
          return true;
        }
      },
      searchFor() {
        if (this.filter.length > 1) this.fetchData(this.activeTab);
        else if (this.filter.length == 0) this.fetchData(this.activeTab);
      },
      filterPage() {
        if (this.pageNo.length > 0) {
          this.params = `&page=${this.pageNo}`;
          this.fetchData(this.activeTab);
          this.currentPage = this.pageNo;
        }
      },
      search(event) {
        if (this.filter.length > 1) {
          if (event.keyCode == 13) {
            this.fetchData(this.activeTab);
          }
        } else if (this.filter.length == 0) this.fetchData(this.activeTab);
      },
      addTag(newTag) {
        this.form.community_selected.push(newTag);
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async fetchData(txt) {
        let url = null;
        if (txt == "active") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url = medisage_blog.getMediBlogCategoriesUrl + "?filter=" + txt + "&search=" + this.filter;
          } else {
            url = medisage_blog.getMediBlogCategoriesUrl + "?filter=" + txt;
          }
        } else if (txt == "trash") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url = medisage_blog.getMediBlogCategoriesUrl + "?filter=" + txt + "&search=" + this.filter;
          } else {
            url = medisage_blog.getMediBlogCategoriesUrl + "?filter=" + txt;
          }
        } else if (txt == "all") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url = medisage_blog.getMediBlogCategoriesUrl + "?filter=" + txt + "&search=" + this.filter;
          } else {
            url = medisage_blog.getMediBlogCategoriesUrl + "?filter=" + txt;
          }
        }
        url += this.params;
        this.getUrl();
        this.$store.commit("loader/updateStatus", true);
        try {
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            this.tableData = responseData;
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
        this.key += 1;
      },
      async fetchCategories(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let url = medisage_blog.getMediBlogCategoriesUrl + "/" + id;
          this.$store.commit("loader/updateStatus", true);
          const data = await this.getRequest(url);
          this.$store.commit("loader/updateStatus", false);
  
          if (data.status) {
            const responseData = data.response;
  
            if (responseData.name != "" && responseData.name != null) {
              this.form.name = responseData.name;
            }
  
            if (responseData.slug != "" && responseData.slug != null) {
              this.form.slug = responseData.slug;
            }

            this.edit.image_url = responseData.image;
  
            this.categories_id = responseData.id;
            this.oldForm = _.clone(this.form);
  
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async submitData() {
        this.$store.commit("loader/updateStatus", true);
        try {
          let url = medisage_blog.getMediBlogCategoriesUrl;
          if (this.$route.name == "edit-categories") {
            url = medisage_blog.getMediBlogCategoriesUrl + "/" + this.categories_id;
          }
          let dataAppend = new FormData();
          if(this.image){
            dataAppend.append("image", this.image);
          }
          for (var key in this.form) {
            // if (
            //   // key != "community_selected" &&
            //   // key != "expert_selected" &&
            //   // key != "knowledge_categories" &&
            //   // key != "video_selected" &&
            //   // key != 'partner_id' &&
            //   // key != 'partner_division_id'
            // ) {
            //   dataAppend.append(key, this.form[key]);
            // }
            dataAppend.append(key, this.form[key]);
          }
          if (this.$route.name == "edit-categories") {
            dataAppend.append("_method", "put");
          }
          const data = await this.postRequest(url, dataAppend);
          if (data.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
            this.$router.push("/medi-blog/categories");
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      getUrl() {
        var url = new URL(window.location);
        if (this.activeTab == null || this.activeTab == "") {
          this.activeTab = "all";
        }
        (url.searchParams.has('filter') ? url.searchParams.set('filter', this.activeTab) : url.searchParams.append('filter', this.activeTab));
        if (this.filter != null && this.filter != "") {
          (url.searchParams.has('search') ? url.searchParams.set('search', this.filter) : url.searchParams.append('search', this.filter));
        } else {
          url.searchParams.delete('search');
        }
        if (this.currentPage != null && this.currentPage != "") {
          (url.searchParams.has('page') ? url.searchParams.set('page', this.currentPage) : url.searchParams.append('page', this.currentPage));
        } else {
          url.searchParams.set('page', 1);
        }
        url.search = url.searchParams;
        url = url.toString();
        history.pushState({}, null, url);
      },
      async updateStatus(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = medisage_blog.mediBlogCategoriesupdateStatus + "/" + id;
          const data = await this.postRequest(url, {
            is_active: !this.tableData.data[index].is_active,
            id: id,
          });
          if (data.status) {
            this.tableData.data[index].is_active = !this.tableData.data[index].is_active;
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
          this.key += 1;
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async deleteCategories(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = medisage_blog.getMediBlogCategoriesUrl + "/" + id;
          const data = await this.postRequest(url, {
            _method: "DELETE",
          });
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async restoreCategories(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = medisage_blog.restoreMediBlogCategories + "/" + id;
          const data = await this.postRequest(url);
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      tabActive() {
        if (this.activeTab == "trash") {
          if (this.table_header.includes("delete")) {
            let index = this.fields.findIndex((item) => item.key == "delete");
            this.fields[index] = {
              key: "restore",
            };
            let table_index = this.table_header.findIndex(
              (item) => item == "delete"
            );
            this.table_header[table_index] = "restore";
          } else {
            this.table_header.push("restore");
            this.fields.push({
              key: "restore",
            });
          }
  
          if (!this.can("restore-categories")) {
            let index = this.fields.findIndex((item) => item.key == "restore");
            if (index !== -1) {
              this.fields.splice(index, 1);
              let table_index = this.table_header.findIndex(
                (item) => item == "restore"
              );
              this.table_header.splice(table_index, 1);
            }
          }
  
          let index = this.fields.findIndex((item) => item.key == "edit");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "edit"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (this.table_header.includes("restore")) {
            if (!this.can("edit-categories")) {
              this.fields[3] = {
                key: "delete",
              };
            } else {
              this.fields[4] = {
                key: "delete",
              };
            }
  
            let table_index = this.table_header.findIndex(
              (item) => item == "restore"
            );
            this.table_header[table_index] = "delete";
          }
  
          if (!this.can("edit-categories")) {
            let index = this.fields.findIndex((item) => item.key == "edit");
            if (index !== -1) {
              this.fields.splice(index, 1);
              let table_index = this.table_header.findIndex(
                (item) => item == "edit"
              );
              this.table_header.splice(table_index, 1);
            }
          } else {
            if (!this.table_header.includes("edit")) {
              this.table_header.push("edit");
              this.fields[3] = {
                key: "edit",
              };
            }
          }
  
          if (!this.can("delete-categories")) {
            let index = this.fields.findIndex((item) => item.key == "delete");
            if (index !== -1) {
              this.fields.splice(index, 1);
              let table_index = this.table_header.findIndex(
                (item) => item == "delete"
              );
              this.table_header.splice(table_index, 1);
            }
          } else {
            if (!this.table_header.includes("delete")) {
              this.table_header.push("delete");
              this.fields[4] = {
                key: "delete",
              };
            }
          }
        }
      },
    },
    watch: {
      currentPage: {
        handler: function (value) {
          if (typeof value != undefined) {
            this.params = `&page=${value}`;
            this.fetchData(this.activeTab);
            this.pageNo = this.currentPage;
          }
        },
      },
      activeTab(v) {
        if (v) this.tabActive();
      },
      "form.is_active"(v) {
        if (v == 1) {
          this.is_active = true;
        } else {
          this.is_active = false;
        }
      },
      "status"(v) {
        if (v == 1) {
          this.fetchAllCommunity();
        } else {
          if (this.$route.name == 'add-categories') {
            this.form.community_selected = [];
          } else if (this.$route.name == 'edit-categories') {
            this.form.community_selected = this.oldForm.community_selected;
          }
        }
      },
    },
    created() {
      if (this.$route.name == "add-categories" || this.$route.name == "edit-categories") {
        if (this.$route.name == "edit-categories") {
          this.fetchCategories(this.$route.params.id);
        }
      } else {
        this.activeTab = "all";
        if (this.$route.query.filter) {
          this.activeTab = this.$route.query.filter;
        }
        if (this.$route.query.search) {
          this.filter = this.$route.query.search;
        }
        if (this.$route.query.page && this.$route.query.page != 1) {
          this.currentPage = parseInt(this.$route.query.page);
        } else {
          this.fetchData(this.activeTab);
        }
      }
      this.fields.map((item) => {
        this.table_header.push(item.key);
      });
      this.tabActive();
    },
  };